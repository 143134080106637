<script lang="ts">
export let link = '/'
export let text = ''
</script>

<a data-sveltekit-reload href={link} class="gradient-button">
  {text}
</a>

<style lang="scss">
a {
  display: inline-block;
  width: 111px;
  padding: 10px;
  border-radius: 29px;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.32px;
  text-align: center;
  background: linear-gradient(
    96.98deg,
    #ff560e -34.51%,
    #ff001f 22.52%,
    #8802c8 94.43%,
    #6202fe 144.6%
  );
  background-size: 150% auto;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-position: right center;
    color: inherit;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 18px;
    line-height: 21px;
  }
}
</style>
